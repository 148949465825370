<!-- eslint-disable -->
<template>
  <v-container fluid>
    <div v-if="loaded">
      <v-card flat color="background">
        <v-card-title primary-title>Branding</v-card-title>
        <v-card-subtitle>Edit Logo, primary, secondary, accent colors for your business</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-card height="100%">
                <v-card-title primary-title>Logo</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-file-input
                    label="Select A Logo"
                    accept="image/*"
                    ref="file"
                    prepend-icon="mdi-camera"
                    v-model="chosenFile"
                    style="display:none"
                    @change="onFilePicked"
                  ></v-file-input>
                  <v-layout row wrap justify-center>
                    <v-hover v-slot:default="{ hover }">
                      <v-img
                        contain
                        aspect-ratio="1.6"
                        @click="$refs.file.$refs.input.click()"
                        class="grey lighten-3"
                        :class="{ 'lighten-2': hover }"
                        :src="logoDisplayImage"
                      ></v-img>
                    </v-hover>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="5">
              <v-card>
                <v-card-title primary-title>Colors</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row class="align-baseline">
                    <v-col class="pb-0" cols="12" md="6">
                      <span
                        class="subtitle-1 font-weight-medium black--text text--lighten-3"
                      >Primary Color</span>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="6">
                      <v-text-field
                        v-model="business.branding.primary_color"
                        readonly
                        @click="menu.primary = true"
                        hide-details
                        class="ma-0 pa-0"
                        outlined
                      >
                        <template v-slot:prepend-inner>
                          <v-menu
                            v-model="menu.primary"
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                left
                                size="20"
                                :color="business.branding.primary_color"
                                v-on="on"
                              >fas fa-palette</v-icon>
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker v-model="business.branding.primary_color" flat />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="align-baseline">
                    <v-col class="pb-0" cols="12" md="6">
                      <span
                        class="subtitle-1 font-weight-medium black--text text--lighten-3"
                      >Secondary Color</span>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="6">
                      <v-text-field
                        @click="menu.secondary = true"
                        v-model="business.branding.secondary_color"
                        readonly
                        hide-details
                        class="ma-0 pa-0"
                        outlined
                      >
                        <template v-slot:prepend-inner>
                          <v-menu
                            v-model="menu.secondary"
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                left
                                size="20"
                                :color="business.branding.secondary_color"
                                v-on="on"
                              >fas fa-palette</v-icon>
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker v-model="business.branding.secondary_color" flat />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="align-baseline">
                    <v-col class="pb-0" cols="12" md="6">
                      <span
                        class="subtitle-1 font-weight-medium black--text text--lighten-3"
                      >Accent Color</span>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="6">
                      <v-text-field
                        v-model="business.branding.accent_color"
                        @click="menu.accent = true"
                        readonly
                        hide-details
                        class="ma-0 pa-0"
                        outlined
                      >
                        <template v-slot:prepend-inner>
                          <v-menu
                            v-model="menu.accent"
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <v-icon
                                left
                                size="20"
                                :color="business.branding.accent_color"
                                v-on="on"
                              >fas fa-palette</v-icon>
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker v-model="business.branding.accent_color" flat />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="align-baseline">
                    <v-col class="pb-0" cols="12" md="6">
                      <span
                        class="subtitle-1 font-weight-medium black--text text--lighten-3"
                      >Business Slug</span>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="6">
                      <v-text-field v-model="businessSlug" hide-details class="ma-0 pa-0" outlined></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="align-baseline">
                    <v-col class="pb-0" cols="12" md="6">
                      <span
                        class="subtitle-1 font-weight-medium black--text text--lighten-3"
                      >Business Website</span>
                    </v-col>
                    <v-col class="pb-0" cols="12" md="6">
                      <v-text-field
                        v-model="businessWebsite"
                        hide-details
                        class="ma-0 pa-0"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="8">
              <v-layout row wrap>
                <v-spacer></v-spacer>
                <v-btn
                  @click="saveBusinessBranding"
                  color="primary"
                  class="text-capitalize"
                >Save Changes</v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <br />
      <v-card flat color="background" v-if="!hideBusinessSettings">
        <v-card-title primary-title>Security Settings</v-card-title>
        <v-card-subtitle>Configure security settings for your users like 2 factor auth, etc.</v-card-subtitle>
        <v-card-text>
          <v-row
            class="ma-0 pa-0 align-center"
            v-for="(item, index) in businessSettings.current"
            :key="item.display_name + index"
          >
            <v-col class="ma-0 pa-0 my-2" cols="12" sm="12" md="8">
              <v-card>
                <v-card-text>
                  <v-row justify="center" align="center" align-content="center">
                    <v-col cols="12" md="9">
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <div>
                            <p class="pa-0 ma-0 subtitle-1 font-weight-bold">{{ item.display_name }}</p>
                            <p
                              class="pa-0 ma-0 subtitle-2 font-weight-regular"
                            >{{ item.description }}</p>
                          </div>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <span>
                            <span v-if="item.value_type === 'boolean'">
                              <v-switch inset class hide-details v-model="item.value"></v-switch>
                            </span>
                            <span v-if="item.value_type === 'string'">
                              <v-text-field v-model="item.value"></v-text-field>
                            </span>
                            <span v-if="item.value_type === 'number'">
                              <v-text-field type="number" v-model.number="item.value"></v-text-field>
                            </span>
                            <span v-if="item.value_type === 'currency'">
                              <v-text-field
                                v-currency="{ 'distraction-free': false, currency: null, locale: 'en', precision: 2, 'auto-decimal-mode': true }"
                                v-model.number="item.value"
                                prefix="$"
                              ></v-text-field>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="3" class="text-right">
                      <v-btn
                        @click="saveBusinessSettings(item, index)"
                        :disabled="enableBusinessSettingsButton(item, index)"
                        color="primary"
                      >
                        <v-icon>check</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        color="background"
        flat
        v-if="locationSettingsCategories.current.length > 0 && !_is50c()"
      >
        <v-card-title primary-title>Locations Settings</v-card-title>
        <v-card-subtitle primary-title>Locations Settings</v-card-subtitle>
        <v-card-text>
          <v-scroll-x-transition group>
            <v-row
              class="ma-0 pa-0 align-center"
              v-for="(item, index) in locationSettingsCategories.current"
              :key="item.display_name + index"
            >
              <v-col class="ma-0 pa-0 my-2" cols="12" sm="12" md="8">
                <v-card>
                  <v-card-text>
                    <v-row justify="center" align="center" align-content="center">
                      <v-col cols="12" md="9">
                        <v-row>
                          <v-col cols="12" class="py-0">
                            <div>
                              <p
                                class="pa-0 ma-0 subtitle-1 font-weight-bold"
                              >{{ item.display_name }}</p>
                              <p
                                class="pa-0 ma-0 subtitle-2 font-weight-regular"
                              >{{ item.description }}</p>
                            </div>
                          </v-col>
                          <v-col cols="12" class="py-0">
                            <span>
                              <v-switch inset class hide-details v-model="item.is_active"></v-switch>
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" md="3" class="text-right">
                        <v-btn
                          @click="toggleLocationSetting(item, index)"
                          :disabled="enableButton(item, index)"
                          color="primary"
                        >
                          <v-icon>check</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-scroll-x-transition>
        </v-card-text>
        <!-- <v-card-text>
          <v-row
            class="ma-0 pa-0 align-center"
            v-for="setting in locationSettingsCategories"
            :key="setting.display_name"
          >
            <v-col class="ma-0 pa-0 my-2" cols="12" md="8" sm="12">
              <v-card>
                <v-card-text>
                  <v-layout>
                    <div>
                      <p class="pa-0 ma-0 subtitle-1 font-weight-bold">{{setting.display_name}}</p>
                      <p class="pa-0 ma-0 subtitle-2 font-weight-regular">{{setting.description}}</p>
                    </div>
                    <v-spacer></v-spacer>
                    <span>
                      <v-switch @change="toggleLocationSetting(setting)" class="pa-0 ma-0" hide-details v-model="setting.is_active"></v-switch>
                    </span>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>-->
      </v-card>
    </div>
  </v-container>
</template>

<script>
/*eslint-disable*/
import { mapGetters, mapActions } from 'vuex'
import { AuthAPI } from '../../clients/auth/auth.api'
import { LocationsAPI } from '../../clients/locations/locationsapi'
export default {
  async created() {
    this.$root.$on('resource-selected', (event) => {
      this.business.branding.logo_url = event[0].full_url
    })
    this.getLocationSettingsCategories()
    await this.getBusiness()
    await this.getBusinessSettings()
    this.businessSlug = this.business.slug
    this.businessWebsite = this.business.website
    this.loaded = true
  },
  data() {
    return {
      business: null,
      menu: {
        primary: false,
        secondary: false,
        accent: false
      },
      businessSettings: {
        current: [],
        initial: []
      },
      loaded: false,
      hideBusinessSettings: false,
      locationSettingsCategories: {
        current: [],
        initial: []
      },
      chosenFile: null,
      chosenFileURL: null,
      businessSlug: ''
    }
  },
  computed: {
    ...mapGetters({
      activeBusiness: 'auth/activeBusiness',
      vg_activeBusinessBrandingAndLogo: 'auth/activeBusinessBrandingAndLogo',
      vg_locationsApplicationDetails: 'applications/isLocationsEnabled'
    }),
    logoDisplayImage() {
      if (this.chosenFileURL) {
        return this.chosenFileURL
      } else {
        return this.business.branding.logo_url
      }
    }
  },
  methods: {
    ...mapActions({
      va_setActiveBusinessBranding: 'auth/setActiveBusinessBranding'
    }),
    async onFilePicked(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.chosenFileURL = e.target.result
        this.chosenFile = file
      }
      reader.readAsDataURL(file)
    },
    async getBusiness() {
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.getBusinessById(this.activeBusiness.id, true)
      } catch (error) {
        this.spinner(false)
        return
      }
      this.spinner(false)
      this.business = response.data.payload
    },
    // openResource() {
    //   this.openResourcePicker()
    // },
    async saveBusinessBranding() {
      this.spinner(true)
      let data
      if (this.chosenFileURL) {
        let response
        let body = {
          'file_name': this.chosenFile.name
        }
        try {
          response = await AuthAPI.saveBusinessSettingsLogo(body)
          let resourceURL = response.data.payload.resource_url
          let uploadURL = response.data.payload.upload_url
          const myHeaders = new Headers()
          myHeaders.append('Content-Type', this.chosenFile.type)
          var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: this.chosenFile
          }
          fetch(uploadURL, requestOptions).then(response => response.text()).then(result => console.log(result)).catch(error => console.log('error', error))
          data = {
            primary_color: this.business.branding.primary_color,
            secondary_color: this.business.branding.secondary_color,
            accent_color: this.business.branding.accent_color,
            logo_url: resourceURL
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        data = {
          primary_color: this.business.branding.primary_color,
          secondary_color: this.business.branding.secondary_color,
          accent_color: this.business.branding.accent_color,
          logo_url: this.business.branding.logo_url
        }
      }
      let response
      try {
        response = await AuthAPI.updateBusinessBranding(this.activeBusiness.id, data)
      } catch (error) {
        this.spinner(false)
        return error
      }
      try {
        await this.va_setActiveBusinessBranding(this.activeBusiness.id)
      } catch (error) {
        this.spinner(false)
        return error
      }
      this.$vuetify.theme.themes.light.primary = this.vg_activeBusinessBrandingAndLogo.primary
      this.$vuetify.theme.themes.light.secondary = this.vg_activeBusinessBrandingAndLogo.secondary
      this.$vuetify.theme.themes.light.accent = this.vg_activeBusinessBrandingAndLogo.accent
      this.spinner(false)
      this.swal({
        title: 'Success',
        text: 'Changes saved successfully.',
        type: 'success'
      })
      if (this.businessSlug !== this.business.slug) {
        AuthAPI.updateBusiness(this.business.id, { slug: this.businessSlug })
        this.spinner(false)
      }
      if (this.businessWebsite !== this.business.website) {
        AuthAPI.updateBusiness(this.business.id, { website_address: this.businessWebsite })
        this.spinner(false)
      }
    },
    async getBusinessSettings() {
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.getBusinessSettings(this.activeBusiness.id)
      } catch (error) {
        console.log('error', error)
        if (error.response.status === 404) {
          this.spinner(false)
          this.hideBusinessSettings = true
          return
        }
        return
      }
      this.businessSettings.current = response.data.payload
      this.businessSettings.initial = JSON.parse(JSON.stringify(this.businessSettings.current))
      this.spinner(false)
    },
    async saveBusinessSettings(item, index) {
      try {
        await AuthAPI.updateBusinessSettings(this.activeBusiness.id, item.id, { value: item.value })
      } catch (error) {
        item.value = this.businessSettings.initial[index].value
        return
      }
      this.businessSettings.initial[index].value = item.value
      this.snackbar({ text: `Business setting updated successfully`, color: 'success', icon: 'check' })
    },
    async getLocationSettingsCategories() {
      let response
      try {
        response = await LocationsAPI.getLocationSettingsCategories(this.activeBusiness.id)
      } catch (error) {
        console.log(error)
      }
      this.locationSettingsCategories.current = response.data.payload
      this.locationSettingsCategories.initial = JSON.parse(JSON.stringify(this.locationSettingsCategories.current))
    },
    async toggleLocationSetting(item, index) {
      console.log(item)
      try {
        await LocationsAPI.toggleLocationSetting(this.activeBusiness.id, item.name, item.is_active)
      } catch (error) {
        item.is_active = !item.is_active
        return
      }
      this.locationSettingsCategories.initial[index].is_active = item.is_active
      this.snackbar({ text: `Location category successfully ${item.is_active ? 'Enabled' : 'Disabled'}`, color: 'success', icon: 'check' })
    },
    enableButton(item, index) {
      return this.locationSettingsCategories.initial[index].is_active === item.is_active
    },
    enableBusinessSettingsButton(item, index) {
      console.log(item, index)
      return this.businessSettings.initial[index].value === item.value
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
